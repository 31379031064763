import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://www.youtube.com/embed/VSF2KVjN1Ws"
    notesSrc="http://bible.com/events/10639879"
    notesText="Sermon Notes"
    bibleGroupSrc="https://www.docdroid.net/l0CSwnP/bible-group-homework-5-3-pdf"
    bibleGroupText="Bible Group Homework"
  >
    <SEO title="When Pigs Fly - Miracles of Protection" />
  </Layout>
)

export default SermonPost
